<template>
    <div class="loginBox flex_c_c">
        <div class="flex_c_c w100 h100" v-if="!token">
            <div class="rightBox">
                <div class="welcomeTxt">你好，欢迎登录</div>
                <div class="flex_l_c item">
                    <img src="../../../../assets/img/login_account.png" class="accountIcon">
                    <el-input v-model="inputForm.mobilePhone" placeholder="请输入用户名称" size="mini"></el-input>
                </div>
                <div class="flex_l_c item">
                    <img src="../../../../assets/img/login_pwd.png" class="pwdIcon">
                    <el-input v-model="inputForm.password" placeholder="请输入密码" size="mini" show-password auto-complete="new-password" @keyup.enter.native="login()"></el-input>
                </div>
                <el-checkbox v-model="rememberPwd">记住密码</el-checkbox>
                <div class="loginBtn pointer" @click="login">登录</div>
            </div>
        </div>
        <div v-else>获取用户权限中，请稍后~</div>
    </div>
</template>

<script>
    import CryptoJS from "crypto-js";
    export default {
        data() {
            return {
                token: this.QueryString('token') || '',
                id: this.QueryString('id') || '',
                inputForm: {
                    mobilePhone: '',
                    password: '',
                    type: 1,
                    xposition: 1
                },
                rememberPwd: false,
                rules: {
                    mobilePhone: [
                        {required: true, message: '帐号不能为空', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '密码不能为空', trigger: 'blur'}
                    ],
                },
            }
        },
        mounted() {
            if (this.token) {
                sessionStorage.setItem('token', this.token)
                document.cookie = `satoken=${this.token}`
                this.getUserInfo();
            }
            this.getCookie();
        },
        methods: {
            // 获取用户信息
            getUserInfo() {
                this.$axios(`${this.api.auth.GetUserById}/${this.id}`, {}, 'get').then(data => {
                    if (data.status && data.data) {
                        this.$store.commit('user/updateUser', data.data)
                        this.getMenus();
                    }
                })
            },
            // 获取用户权限
            getMenus() {
                this.$axios(this.api.auth.getMenus, {}, 'get').then(data => {
                    if (data.status) {
                        sessionStorage.setItem('allMenuList', JSON.stringify(data.data.menuList || '[]'))
                        sessionStorage.setItem('permissions', JSON.stringify(data.data.permissions || '[]'))
                        this.$router.push({name: 'home'})
                    }else {
                        this.$message.error(data.msg)
                    }
                })
            },
            // 登录
            login() {
                if(this.inputForm.mobilePhone == '' || this.inputForm.password == '') {
                    this.$message.error('请输入账号或密码');
                    return false
                }
                if (this.rememberPwd) {
                    this.setCookie(this.inputForm.mobilePhone, this.inputForm.password, 7);
                }else {
                    //清空Cookie
                    this.clearCookie();
                }
                this.$axios(this.api.auth.login, this.inputForm, 'post').then(data => {
                    if (data.status) {
                        sessionStorage.setItem('token', data.data.token)
                        document.cookie=`satoken=${data.data.token}`
                        this.$store.commit('user/updateUser', data.data)
                        this.socketApi.initWebSocket()
                        this.getMenus();
                    } else {
                        this.$message({
                            message: data.msg,
                        });
                    }
                })
            },
            //储存cookie
            setCookie(name, pwd, exdays) {
                var exdate = new Date();
                exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
                // Encrypt，加密账号密码
                var cipherName = CryptoJS.AES.encrypt(name + "", "secretkey123").toString();
                var cipherPwd = CryptoJS.AES.encrypt(pwd + "", "secretkey123").toString();
                document.cookie = "xxUserName=" + cipherName + ";path=/;expires=" + exdate.toGMTString()
                document.cookie = "xxUserPwd=" + cipherPwd + ";path=/;expires=" + exdate.toGMTString()
            },
            //获取cookie
            getCookie: function() {
                if (document.cookie.length > 0) {
                    var arr = document.cookie.split('; ');
                    for (var i = 0; i < arr.length; i++) {
                        var arr2 = arr[i].split('=');
                        if (arr2[0] == 'xxUserName') {
                            var bytes = CryptoJS.AES.decrypt(arr2[1], "secretkey123");
                            this.inputForm.mobilePhone = bytes.toString(CryptoJS.enc.Utf8);
                        } else if (arr2[0] == 'xxUserPwd') {
                            var bytes = CryptoJS.AES.decrypt(arr2[1], "secretkey123");
                            this.inputForm.password = bytes.toString(CryptoJS.enc.Utf8);
                            if(arr2[1] != "") {
                                this.rememberPwd = true;
                            }
                        }

                    }
                }
            },
            //删除cookie
            clearCookie: function() {
                this.setCookie("", "", -1);
            },
        }
    }
</script>

